<template>
  <div>
    <div class="part-box" :style="`margin-bottom: 5px`">
      <div class="part-header">
        起搏器常规随访
      </div>
      <el-popover
        placement="bottom-start"
        width="250"
        trigger="hover">
        <div class="part-content">
          <div class="part-item">
            <span class="label">推送日期：</span>
            <span class="value">第1,3,6,12,24,36,48月</span>
          </div>
          <div class="part-item">
            <span class="label">随访前后天数：</span>
            <span class="value">7天</span>
          </div>
        </div>
        <div slot="reference" class="red-mark"></div>
      </el-popover>
    </div>
    <div class="search-box">
      <SearchComp :search-opts="searchOpts" :search-data="searchData" search-label="查询" @search="handleSearch" search-type="primary" labelWidth="80px"></SearchComp>
    </div>
    <table-comp
      :table-opts="tableOpts"
      :data="tableData"
      :page="currentPage"
      :total="totalPage"
      :page-size="pageSize"
      :show-number="true"
      :open-handle="true"
      :handle-opts="handleOpts"
      @handleSizeChange="sizeChange"
      @handleCurrentChange="pageChange"
    >
      <template #visitRecords="{ msg }">
        <el-popover
          placement="top-start"
          title=""
          width="220"
          trigger="hover"
          data-html="true"
          >
          <span v-for="(item,index) in msg.visitRecords" :key="index" :class="!item.isVisit ? 'text-red':'text-black'">{{item.text}}<br></span>
          <div slot="reference">
            <div class="show-visit-records">
              <span v-for="(item,index) in msg.visitRecords" :key="index" :class="!item.isVisit ? 'text-red':'text-black'">{{item.text}}<br></span>
            </div>
<!--            <span v-if="msg.visitRecords && msg.visitRecords.length===0">-</span>-->
          </div>
        </el-popover>
      </template>
      <template #btn_1="{ msg }">
        <el-button style="font-size: 14px;" size="mini" type="text" @click="other_1(msg)">开始随访</el-button>
      </template>
      <template #btn_2="{ msg }">
        <el-button style="font-size: 14px;" size="mini" type="text" @click="other_2(msg)">患者档案</el-button>
      </template>
    </table-comp>
  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import { getFollowUpList, getFollowUpCycleList } from '@/service/module/followup'
import SearchComp from '@/components/Search/Search'
export default {
  components: {
    TableComp,
    SearchComp
  },
  data () {
    return {
      searchOpts: [
        {
          label: '病案号',
          key: 'recordNo',
          placeholder: '请输入病案号',
          type: 'input'
        },
        {
          label: '随访周期',
          key: 'seqNo',
          placeholder: '请选择',
          type: 'select',
          opts: this.curFollowUpPeriodList,
          labelText: 'name',
          valText: 'code'
        },
        {
          label: '随访状态',
          key: 'periodStatus',
          placeholder: '请选择',
          type: 'select',
          labelText: 'name',
          valText: 'code',
          opts:
          [
            { code: 0, name: '未开始' },
            { code: 1, name: '进行中' },
            { code: 2, name: '已完成' }
            // { code: 3, name: '已超窗' }
          ] // 随访状态列表
        }
      ],
      searchData: {
        input: '',
        seqNo: '',
        periodStatus: ''
      },
      tableOpts: [ // 列表表头设置
        {
          label: '患者姓名',
          prop: 'name',
          render: (h, params) => {
            const name = params.row.name
            const nameText = '-'
            if (!name) {
              return h('span', {}, nameText)
            } else {
              return h('span', {}, name)
            }
          }
        },
        {
          label: '性别',
          prop: 'gender',
          render: (h, params) => {
            const gender = params.row.gender
            let genderText = ''
            switch (gender) {
              case 2 : genderText = '女'
                break
              case 1 : genderText = '男'
                break
            }
            return h('span', {}, genderText)
          }
        },
        {
          label: '年龄',
          prop: 'age',
          render: (h, params) => {
            const age = params.row.age
            const ageText = '-'
            if (!age) {
              return h('span', {}, ageText)
            } else {
              return h('span', {}, age)
            }
          }
        },
        {
          label: '注册手机号',
          prop: 'phone',
          render: (h, params) => {
            const phone = params.row.phone
            const phoneText = '-'
            if (!phone) {
              return h('span', {}, phoneText)
            } else {
              return h('span', {}, phone)
            }
          }
        },
        {
          label: '病案号',
          prop: 'recordNo',
          render: (h, params) => {
            const recordNo = params.row.recordNo
            const recordNoText = '-'
            if (!recordNo) {
              return h('span', {}, recordNoText)
            } else {
              return h('span', {}, recordNo)
            }
          }
        },
        {
          label: '植入日期',
          prop: 'implTime',
          render: (h, params) => {
            var moment = require('moment')
            const implantDate = moment.unix(params.row.implTime / 1000).format('Y-MM-DD')
            return h('span', {}, implantDate)
          }
        },
        {
          label: '随访状态',
          prop: 'periodStatus',
          render: (h, params) => {
            const gender = params.row.periodStatus
            let statusText = '-'
            switch (gender) {
              case 0 : statusText = '未开始'
                return h('el-tag', { props: { type: 'info', size: 'small' } }, statusText)
              case 1 : statusText = '进行中'
                return h('el-tag', { props: { size: 'small' } }, statusText)
              // case 3 : statusText = '已超窗'
              //   return h('el-tag', { props: { type: 'danger', size: 'small' } }, statusText)
              case 2 : statusText = '已完成'
                return h('el-tag', { props: { type: 'success', size: 'small' } }, statusText)
            }
            return h('span', {}, statusText)
          }

        },
        {
          label: '到院复诊情况',
          prop: 'visitRecords',
          slot: 'visitRecords'
        },
        {
          label: '当前随访周期',
          prop: 'periodText',
          render: (h, params) => {
            const currentperiod = params.row.periodText
            const periodText = '-'
            if (!currentperiod) {
              return h('span', {}, periodText)
            } else {
              return h('span', {}, currentperiod)
            }
          }
        }
      ],
      handleOpts: {
      },

      value: null,
      curFollowUpPeriodList: [],

      tableData: [],
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20 // 每页条数

      // searchInfo: {
      // } // 查询信息
    }
  },
  created () {
    getFollowUpCycleList().then(res => {
      if (res.data) {
        this.curFollowUpPeriodList = res.data
        this.searchOpts.forEach(i => {
          if (i.key === 'seqNo') {
            i.opts = this.curFollowUpPeriodList
          }
        })
      }
    })
  },
  mounted () {
    this.getFollowUpList(1, this.pageSize)
  },
  methods: {
    splicingString (list) {
      let str = ''
      list.forEach(i => {
        str += i.text + '<br/>'
      })
      return str
    },
    async getFollowUpList (pageNum, pageSize) {
      try {
        this.searchData.pageNum = pageNum
        this.searchData.pageSize = pageSize
        const res = await getFollowUpList(
          this.searchData)
        if (res) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
          this.currentPage = res.data.currentPage
          this.pageSize = res.data.pageSize
        }
      } catch (e) {
        console.log('awaitList-err:', e)
      }
    },
    handleSearch () {
      this.getFollowUpList(1, this.pageSize)
    },
    other_1 (data) {
      console.log(data)
      const { periodId, planId } = data
      this.$router.replace({
        name: 'followupDetail',
        query: {
          periodId,
          planId
        }
      })
    },
    other_2 (data) {
      this.$router.push({
        name: 'patientFiles',
        query: {
          planId: data.planId,
          patientId: data.patientId
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getFollowUpList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getFollowUpList(val, this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
.part-box {
  display: flex;
  align-items: center;
  .part-header {
    font-size: 14px;
    color: #131414;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .red-mark {
    width: 14px;
    height: 14px;
    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/icon-gray-mark.png") no-repeat;
    margin-left: 5px;
  }
  .part-content {
    .part-item {
      .val, .label {
        color: #131414;
        line-height: 1.4;
        font-size: 12px;
      }
    }
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 730px;
  margin: 10px 0;
  .search-item {
    width: 240px;
  }
  .el-input, .el-select {
    width: 150px;
  }
}
.text-red {
  color: red;
}
.show-visit-records {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
