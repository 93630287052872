<template>
  <div class="searchWrap">
    <el-form :size="size" :inline="true" ref="form" :model="searchData" :label-width="labelWidth">
      <el-form-item v-for="(i, index) in searchOpts" :key="index" :label="i.label">
<!--    type = 'input'    -->
        <el-input v-if="i.type === 'input'" v-model="searchData[i.key]" :placeholder="i.placeholder ? i.placeholder : ''"></el-input>
<!--    type = 'select'    -->
        <el-select
          v-else-if="i.type === 'select'"
          v-model="searchData[i.key]"
          :placeholder="i.placeholder ? i.placeholder : ''"
          clearable>
          <el-option
            v-for="(item, o_index) in i.opts"
            :key="o_index"
            :label="item[i.labelText]"
            :value="item[i.valText]"
          >
          </el-option>
        </el-select>
<!--    type = 'datetime'    -->
        <el-date-picker
          v-if="i.type === 'datetime'"
          v-model="searchData[i.key]"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
<!--    type = 'remote'    -->
        <el-select
          v-if="i.type === 'remote'"
          v-model="searchData[i.key]"
          filterable
          clearable
          remote
          :placeholder="i.placeholder ? i.placeholder : ''"
          :remote-method="i.handler"
          :loading="i.loading"
          @clear="handleClear"
          >
          <el-option
            v-for="(item, o_index) in i.opts"
            :key="o_index"
            :label="item.text"
            :value="item.val">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button v-if="searchLabel" :type="searchType" @click="handleSearch">{{searchLabel}}</el-button>
        <el-button v-if="addLabel" :type="addType" @click="handleAdd">{{addLabel}}</el-button>
        <el-button v-if="resetLabel" type="warning" @click="handleReset">{{resetLabel}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    searchType: {
      type: String,
      default: 'primary'
    },
    addType: {
      type: String,
      default: 'primary'
    },
    searchOpts: {
      type: Array,
      require: true
    },
    searchData: {
      type: Object,
      require: true
    },
    addLabel: String,
    searchLabel: String,
    resetLabel: String,
    size: {
      type: String
    },
    labelWidth: {
      type: String,
      default: '100px'
    }
  },
  methods: {
    handleAdd () {
      this.$emit('add')
    },
    handleSearch () {
      this.$emit('search', this.searchData)
    },
    handleReset () {
      this.$emit('reset')
    },
    handleClear () {
      this.$emit('clear')
    }
  }
}
</script>

<style scoped>

</style>
