import { get, post } from '@http/axios'

// 获取随访列表
export const getFollowUpList = (params) => get('/api/doctor-platform/followup/plan/list', { params })

// 获取随访周期列表
export const getFollowUpCycleList = () => get('/api/doctor-platform/followup/plan/codes/periods')

// 字典接口
// 1.文化程度：eduBackground
// 2.基础疾病：underlyingDisease
// 3.品牌：ciedBrand
export const getDictionary = (params) => get(`/api/platform/dictionary/${params}`)

// *患者档案
// 1.患者档案基线信息
export const getBaselineInfo = (patientId) => get(`/api/doctor-platform/followup/patient/baseline/${patientId}`)

// 2.更新基线信息
export const editBaselineInfo = (params) => post('/api/doctor-platform/followup/patient/baseline', params)

// 3.心理量表
export const getPsycScale = (params) => get('/api/doctor-platform/followup/user-survey/record/list', { params })

// 4.心理量表详情
export const getPsycScaleDetail = (answerId) => get(`/api/doctor-platform/followup/user-survey/record/detail?answerId=${answerId}`)

// 5.查询用户随访记录
export const getFollowUpRecords = (params) => get('/api/doctor-platform/followup/user-followup/record/list?', { params })

// 6.查询用户已逾期的随访记录
export const getOverFollowUpRecords = (params) => get('/api/doctor-platform/followup/user-followup/period/list', { params })

// 7. 查询用户随访表单
export const getFollowUpForm = (params) => get('/api/doctor-platform/followup/user-followup/form/get', { params })

// 开始随访获取信息
export const getFollowUpDetail = (params) => get('/api/doctor-platform/followup/plan/period', { params })

// 保存用户随访表单
export const saveFollowUpForm = (params) => post('/api/doctor-platform/followup/user-followup/form/save', params)

// 获取植入信息
export const getImplant = (params) => get(`/api/doctor-platform/followup/patient/implant/${params}`)

// 保存植入信息
export const saveImplant = (params) => post('/api/doctor-platform/followup/patient/implant', params)

// 文件列表
export const getfileList = (recordId) => get(`/api/doctor-platform/followup/user-followup/record/file/${recordId}`)

// 完成随访
export const submitFollowUpInfo = (params) => post('/api/doctor-platform/followup/user-followup/submit', params)
